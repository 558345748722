document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        _scrollTo(document.querySelector(this.getAttribute('href')), -90);
    });
});

function _scrollTo(el, yOffset){
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
}

window.showMenu = function showMenu(menuBtn) {
    const x = document.getElementById("menu");
    if (x.style.display === "block") {
        x.style.display = "none";
    } else {
        x.style.display = "block";
    }
    menuBtn.classList.toggle("change");
}

window.closeMenu = function closeMenu() {
    if (window.innerWidth < 1024) {
        document.getElementById("menu").style.display = "none";
        document.getElementById("menuButton").classList.toggle("change");
    }
}

window.closeModal = function closeModal() {

}